import * as React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <main className="flex flex-col h-screen bg-black-1">
      <Navbar />
      <div className="grid place-items-center h-screen">
        <div className="mx-4 pt-48">
          <p className="text-white text-8xl md:text-9xl font-main text-center">
            4<span className="text-red-1">0</span>4
          </p>
          <p className="text-white text-center pt-8 text-sm md:text-lg">
            Page not found.
          </p>
        </div>
        <div>
          <Link to="/">
            <span className="text-white font-bold font-main text-sm md:text-lg">
              Go back
            </span>
          </Link>
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default NotFoundPage
